import { createClient } from "postchain-client";

import { iTypedGtxClient } from "../../interfaces/chrInterfaces";
import { chromiaBlockchainRID, chromiaBlockchainUrl } from "../../settings";
import { eChromiaActionTypes } from "../../store/chromia/chromiaTypes";
import { store } from "../../store/store";

export const getGtxClient = async (): Promise<iTypedGtxClient> => {
	const { gtxClient } = store.getState().chromia;
	
	if (gtxClient) return gtxClient;

	/* const response = await fetch(`${chromiaBlockchainUrl}brid/iid_0`);
	if (!response.ok) throw new Error();
	const rid = await response.text(); */

	const newGtxClient = (await createClient({
		nodeUrlPool: chromiaBlockchainUrl,
		blockchainRid: chromiaBlockchainRID,
		// blockchainRid: rid,
	})) as iTypedGtxClient;

	store.dispatch({
		type: eChromiaActionTypes.SET_GTX_CLIENT,
		payload: newGtxClient
	})

	return newGtxClient;
}