import { FC, useState } from "react";
// import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";

import { useStoreDispatch, useStoreSelector } from "../../store/store";

// import StructureLogoImg from '../../assets/structureLogo.svg';
import WalletIcon from "../UI/Icons/WalletIcon/WalletIcon";
import AppInfo from "../AppInfo/AppInfo";

import './Header.scss';
import { useDisconnect } from "wagmi";


const Header: FC = () => {

	const { disconnect: disconnectWallet } = useDisconnect();
	// const userAddress = useTonAddress();
	// const [tonConnectUi] = useTonConnectUI();
	const { clearAppState, clearChrState, clearModalsState, clearUIState } = useStoreDispatch();
	const { windowWidth, userAddress } = useStoreSelector(store => store.UI);

	const [isHovered, setIsHovered] = useState(false);

	const disconnect = () => {
		// tonConnectUi.disconnect();
		disconnectWallet();
		clearChrState();
		clearAppState();
		clearModalsState();
		clearUIState();
	}


	return (
		<div className="_Header">
			{/* <div className='_Header_logo'>
				<a
					href='https://structure.finance'
					target='_blank'
					rel="noreferrer"
				>
					<img src={StructureLogoImg} alt='' />
				</a>
			</div> */}

			<div className="_Header_divider1"/>

			{/* {windowWidth > 768 && */}
			{windowWidth > 1024 &&
				<AppInfo/>
			}

			<div className="_Header_divider2"/>

			<div className="_Header_rightBlock">
				<div
					className="_Header_rightBlock_accountBlock"
					onMouseOver={()=>setIsHovered(true)}
					onMouseOut={()=>setIsHovered(false)}
					// tabIndex={is_tabindex? 0 : undefined}
					onFocus={()=>setIsHovered(true)}
					onBlur={()=>setIsHovered(false)}
					onClick={() => userAddress && disconnect()}
				>
					<div
						className="_Header_rightBlock_accountBlock_text"
					>
						{userAddress
							? isHovered
								? 'DiSCONNECT'
								: userAddress.slice(0,6) + '...' + userAddress.slice(-4)
							: 'WALLET'
						}
					</div>
					<div className="_Header_rightBlock_accountBlock_walletIcon">
						<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							{(isHovered && userAddress) ?
								<path d="M6.32812 13.5H3.375C1.51172 13.5 0 11.9883 0 10.125V3.375C0 1.51172 1.51172 0 3.375 0H6.32812C6.56016 0 6.75 0.189844 6.75 0.421875V1.82812C6.75 2.06016 6.56016 2.25 6.32812 2.25H3.375C2.75273 2.25 2.25 2.75273 2.25 3.375V10.125C2.25 10.7473 2.75273 11.25 3.375 11.25H6.32812C6.56016 11.25 6.75 11.4398 6.75 11.6719V13.0781C6.75 13.3102 6.56016 13.5 6.32812 13.5ZM10.473 2.84414L13.2012 5.34375H6.46875C6.00117 5.34375 5.625 5.71992 5.625 6.1875V7.3125C5.625 7.78008 6.00117 8.15625 6.46875 8.15625H13.2012L10.473 10.6559C10.118 10.9793 10.1074 11.5348 10.4449 11.8758L11.2148 12.6457C11.5418 12.9727 12.0762 12.9762 12.4066 12.6492L17.7504 7.34766C18.0844 7.01719 18.0844 6.4793 17.7504 6.14883L12.4102 0.854297C12.0797 0.527344 11.5488 0.527344 11.2184 0.857813L10.4484 1.62773C10.1074 1.96523 10.1215 2.5207 10.473 2.84414Z" fill="#CAFFFF"></path>
							:
								<path d="M16.2141 3.21429H2.8125C2.50172 3.21429 2.25 2.97455 2.25 2.67857C2.25 2.38259 2.50172 2.14286 2.8125 2.14286H16.3125C16.6233 2.14286 16.875 1.90312 16.875 1.60714C16.875 0.719531 16.1195 0 15.1875 0H2.25C1.00723 0 0 0.959263 0 2.14286V12.8571C0 14.0407 1.00723 15 2.25 15H16.2141C17.1991 15 18 14.2791 18 13.3929V4.82143C18 3.93516 17.1991 3.21429 16.2141 3.21429ZM14.625 10.1786C14.0038 10.1786 13.5 9.69877 13.5 9.10714C13.5 8.51551 14.0038 8.03571 14.625 8.03571C15.2462 8.03571 15.75 8.51551 15.75 9.10714C15.75 9.69877 15.2462 10.1786 14.625 10.1786Z" fill="#CAFFFF"></path>
							}
						</svg>
						<WalletIcon/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header;