import { FC, useEffect } from "react";
// import { useTonAddress } from "@tonconnect/ui-react";

import { emptyBet, emptyOrder, iBet, iClosedBet, iOrder, iPrice } from "../../../interfaces/interfaces";
import { iChrOrder } from "../../../interfaces/chrInterfaces";
import { store, useStoreDispatch, useStoreSelector } from "../../../store/store";
import { blockchain } from "../../../settings";
import { getAllAssetsQuery, getClientBets, getHedgerOrders } from '../../../utils/chromia/queries';
import { BigNumber } from "../../../classes/BigNumber";


const BalanceUpdater: FC = () => {

	// const userAddress = useTonAddress();
	const { apis, currentCurrency } = useStoreSelector(store => store.app);
	const { userAddress } = useStoreSelector(store => store.UI);
	const { userAddress: chrUserAddress } = useStoreSelector(store => store.chromia);
	const { setCurrentBet, setCurrentPrice, setBestOrder, setTimestamp, setLastOrder, setChrBestOrder, setLastChrBet } = useStoreDispatch();

	useEffect(() => {
		let intervalFunc: (() => void) | undefined;

		switch(blockchain) {
			case 'chromia':
				intervalFunc = (() => {
					// getAllAssetsQuery()
					getCHROrders();
					getDateAndBTC();
					chrUserAddress && updateChrBet(chrUserAddress);
				})
				break;

			case "binance":
				break;

			case "ton":
				intervalFunc = (() => {
					getCurrentTONBet();
					getTONOrders();
					getLastTONOrder();
				})
				break;

			default:
				throw new Error();
		}

		intervalFunc && intervalFunc();
		let interval = setInterval(() => {
			intervalFunc && intervalFunc();
		}, 5000)

		return () => {
			clearInterval(interval)
		}
		// eslint-disable-next-line
	}, [userAddress, apis, currentCurrency, chrUserAddress])

	/* useEffect(() => {
		getDateAndBTC();
		getCurrentTONBet();
		getTONOrders();
		getLastTONOrder();

		let interval = setInterval(() => {
			getDateAndBTC();
			getCurrentTONBet();
			getTONOrders();
			getLastTONOrder();
		}, 5000)

		return () => {
			clearInterval(interval)
		}
		// eslint-disable-next-line
	}, [userAddress, apis, currentCurrency]) */

	const getCHROrders = async () => {
		const { userAddress } = store.getState().chromia;
		let orders = await getHedgerOrders();

		/* if (orders.length === 0){
			setChrBestOrder(null);
			return;
		} */
		// console.log('orders: ', orders);

		orders = orders.filter(order => order.from.string !== userAddress && order.state !== 'taken');

		let maxLowPercent = new BigNumber(0),
		tempOrders: iChrOrder[] = [];
 
		// first sort: barPerc
		for (let i=0; i<orders.length; i++){
			if (orders[i].lowPercent.valueBN18.gt(maxLowPercent.valueBN18)) {
				maxLowPercent = orders[i].lowPercent;
			}
		}

		for (let i=0; i<orders.length; i++){
			if (orders[i].lowPercent.valueBN18.eq(maxLowPercent.valueBN18)) {
				tempOrders.push(orders[i]);
			}
		}

		// second sort: ft_amt
		let bestOrder = tempOrders[0];
		for (let i=1; i< tempOrders.length; i++){
			if (+bestOrder.ftAmount < +tempOrders[i].ftAmount){
				bestOrder = tempOrders[i];
			}
		}

		// if (!bestOrder) return emptyOrder;
		// return bestOrder;
		setChrBestOrder(bestOrder || null);
	}
	const updateChrBet = async (address: string) => {

		const allClientBets = await getClientBets(address);
		// check for unpaid bets
		const unpaidBets = allClientBets.filter(bet => !bet.isPaid && bet.state === 'fin_client_win');
		if (unpaidBets.length > 0){
			setLastChrBet(unpaidBets[0]);
			return;
		}
		// console.log('allClientBets: ', allClientBets);
		if (allClientBets.length > 0){
			const {lastBet} = store.getState().chromia;
			const newBet = allClientBets[allClientBets.length-1];

			let betStartPrice = (lastBet?.id === newBet.id)? lastBet?.startPrice : undefined;
			if (!betStartPrice) {
				if (!apis[currentCurrency]) throw new Error();
				const response = await fetch(apis[currentCurrency] + '/price/?ts=' + newBet.startTime);
				if (!response.ok){
					throw new Error();
				}
				const res: iPrice = await response.json();
				betStartPrice = new BigNumber(res.price);
			}

			setLastChrBet({...newBet, startPrice: betStartPrice});
		} else {
			setLastChrBet(null)
		}
	}

	const getCurrentTONBet = async () => {
		if (!apis[currentCurrency] || !userAddress){
			setCurrentBet(currentCurrency, emptyBet);
			return;
		}
		// let response = await fetch(apis.TON + `/client/accounts?client_addr=${userAddress}`);
		let response = await fetch(apis[currentCurrency] + `/client/get_active_periods_for_address?address=${userAddress}`);
		let result: iBet | null = await response.json();

		// if (result){
		// 	result.low_bar = toFixed(result.low_perc, 2);
		// 	result.high_bar = toFixed(result.high_perc, 2);
		// }
		
		setCurrentBet(currentCurrency, result || emptyBet);
	}

	const getDateAndBTC = async () => {
		const api = apis[currentCurrency];
		if (!api) {
			return;
		}
		
		let response = await fetch(api + '/price/');
		if (response.ok) {
			let result: iPrice = await response.json();
			// console.log('price', result);
			setCurrentPrice(result);
			
			setTimestamp(result.time*1000);
		}
	}

	const getTONOrders = async () => {
		if (!apis[currentCurrency]) {
			setBestOrder(currentCurrency, emptyOrder);
			return;
		}

		const response = await fetch(apis[currentCurrency] +'/client/orders');
		if (response.ok) {
			let result: iOrder[] = await response.json();
			setBestOrder(currentCurrency, getBestOrder(result) || emptyOrder)
		}
		// add else ?
	}
	
	const getBestOrder = (orders: iOrder[]): iOrder | undefined => {
		if (orders.length === 0) return undefined;
		
		/* // smaller, but slower
		console.time('FirstWay')
		// first sort: bar_perc
		const maxLowPerc = Math.max(...orders.map(order => +order.low_perc));
		const tempOrders = orders.filter(order => +order.low_perc ===  maxLowPerc);
		// second sort: ft_amt
		const bestOrder = tempOrders.reduce((prev, curr) => +prev.ft_amt > +curr.ft_amt ? prev : curr);
		console.timeEnd('FirstWay');
		return bestOrder; */

		
		// console.time('SecondWay')
		let max_low_perc = 0,
			temp_orders: iOrder[] = [];

		// first sort: bar_perc
		for (let i=0; i<orders.length; i++){
			if (+orders[i].low_perc > max_low_perc) {
				max_low_perc = +orders[i].low_perc;
			}
		}

		for (let i=0; i<orders.length; i++){
			if (+orders[i].low_perc === max_low_perc) {
				temp_orders.push(orders[i]);
			}
		}

		// second sort: ft_amt
		let best_order = temp_orders[0];
		for (let i=1; i< temp_orders.length; i++){
			if (+best_order.ft_amt < +temp_orders[i].ft_amt){
				best_order = temp_orders[i];
			}
		}
		// console.timeEnd('SecondWay');
		// console.log('================')
		return best_order;
	}

	const getLastTONOrder = async () => {
		if (!apis[currentCurrency] || !userAddress){
			return;
		}
		// let response = await fetch(apis.TON + `/client/closed_accounts?client_addr=${userAddress}`);	// default limit = 1
		let response = await fetch(apis[currentCurrency] + `/client/get_periods_for_address?address=${userAddress}&is_last=true`);	// default limit = 1
		// let res: [iClosedBet | undefined] = await response.json();
		let res: iClosedBet | null = await response.json();	// iClosedBet[] if is_last=false
		if (res){
			setLastOrder(currentCurrency, res);
		}
	}


	return null;
}

export default BalanceUpdater;