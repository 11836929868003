import { FC } from "react";
// import { useNetwork } from "wagmi";

// import { chainId } from "../../../settings";
// import { useStoreDispatch } from "../../../store/store";


const ChainHandler: FC = () => {

	// const { chain } = useNetwork()
	// const { setIsWrongChain } = useStoreDispatch();

	/* useEffect(() => {
		setIsWrongChain(+chainId !== chain?.id)
		// eslint-disable-next-line
	}, [chain]) */


	return null;
}

export default ChainHandler;