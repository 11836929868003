import { BigNumber } from "../../classes/BigNumber";
import { iChrBet, iChrItem, iChrOrder } from "../../interfaces/chrInterfaces";
import { itemId } from "../../settings";
import { store } from "../../store/store";
import { getGtxClient } from "./getGtxClient";

export const getAllAssetsQuery = async () => {
	const response = await (await getGtxClient()).query("ft4.get_all_assets", { page_size: 100, page_cursor: null });

	const res = response.data.map(asset => { return {
		...asset,
		id: asset.id.toString('hex'),

	}});

	console.log('ft4.get_all_assets: ', res);

	return res;
}

// export const getAssetBallancesQuery = async (assetId: string) => {
export const getAssetBallancesQuery = async () => {
	const { userAddress } = store.getState().chromia;
	if (!userAddress) throw new Error();

	// const response = await (await getGtxClient()).query("ft4.get_asset_balances", { account_id: chrClientAddr, asset_id: assetId });
	const response = await (await getGtxClient()).query("ft4.get_asset_balances", { account_id: userAddress,  page_size: 100, page_cursor: null});

	const res = response.data.map(balance => { return {
		amount: +new BigNumber(balance.amount.toString(), {decimals: balance.asset.decimals, currentDecimals: balance.asset.decimals}).valueStr,
		asset: {
			...balance.asset,
			id: balance.asset.id.toString('hex')
		}
	}});

	console.log('ft4.get_asset_balances: ', res);

	return res;
}

export const getAssetBallanceQuery = async (assetId: string) => {
	const { userAddress } = store.getState().chromia;
	if (!userAddress) throw new Error();

	const response = await (await getGtxClient()).query("ft4.get_asset_balance", { account_id: userAddress, asset_id: assetId });

	if (!response) throw new Error();

	const res = {
		amount: new BigNumber(response.amount.toString(), {decimals: response.asset.decimals, currentDecimals: response.asset.decimals}).valueStr,
		asset: {
			...response.asset,
			id: response.asset.id.toString('hex')
		}
	};

	console.log('ft4.get_asset_balance: ', res);

	return res;
}

export const getItemsQuery = async (): Promise<iChrItem[]> => {
	const response = await (await getGtxClient()).query("dnt.GetItems", {});

	if (!response) throw new Error();

	const res: iChrItem[] = response.map(item => { return {
		creationTime: item.created_time,
		fee: new BigNumber(item.hedg_fee),
		ftAddress: {
			string: item.ft.toString('hex'),
			buffer: item.ft
		},
		id: {
			string: item.acc.toString('hex'),
			buffer: item.acc
		},
		owner: {
			string: item.owner.toString('hex'),
			buffer: item.owner
		},
		pricePool: {
			string: item.com_tok_price_pool.toString('hex'),
			buffer: item.com_tok_price_pool
		},
		status: Boolean(item.in_work)
	}});

	console.log('dnt.GetItems: ', res);

	return res;
}

export const getHedgerOrders = async (): Promise<iChrOrder[]> => {
	const response = await (await getGtxClient()).query('dnt.GetAllHedgOrdsByItem', {item_id: itemId});

	if (!response) throw new Error();

	const res: iChrOrder[] = response.map(item => { return {
		duration: item.duration,
		feeAmount: new BigNumber(item.fee_amt),
		from: {
			string: item.from.toString('hex'),
			buffer: item.from,
		},
		ftAmount: new BigNumber(item.ft_amt),
		highPercent: new BigNumber(item.high_perc),
		id: item.id,
		itemId: {
			string: item.item_id.toString('hex'),
			buffer: item.item_id,
		},
		lowPercent: new BigNumber(item.low_perc),
		minUsePercent: new BigNumber(item.min_use_perc),
		state: item.state,
	}});

	// console.log('dnt.GetAllHedgOrd: ', res);

	return res;
}

export const getClientBets = async (accId: string): Promise<iChrBet[]> => {
	const response = await (await getGtxClient()).query('dnt.GetClientAccsByItemAndAcc', {item_id: itemId, acc_id: accId});

	const res: iChrBet[] = response.map(bet => {return {
		endTime: bet.end_time,
		from: {
			string: bet.from.toString('hex'),
			buffer: bet.from,
		},
		ftAmount: new BigNumber(bet.ft_amt),
		hedger: {
			string: bet.hedger.toString('hex'),
			buffer: bet.hedger,
		},
		highPercent: new BigNumber(bet.high_perc),
		id: bet.id,
		itemId: {
			string: bet.item_id.toString('hex'),
			buffer: bet.item_id,
		},
		lowPercent: new BigNumber(bet.low_perc),
		isPaid: Boolean(bet.paid),
		startTime: bet.start_time,
		state: bet.state,
	}})

	// console.log('dnt.GetClientAccReqByItemAndAcc: ', res);

	return res;
}
/* export const getClientBets = async (accId: string): Promise<iChrBet[]> => {
	const response = await (await getGtxClient()).query('dnt.GetHistClientAccByItemAndAcc', {item_id: itemId, acc_id: accId, beg_time: 1});

	console.log('dnt.GetHistClientAccByItemAndAcc: ', response);

	const res: iChrBet[] = response.map(bet => {return {
		endTime: bet.end_time,
		from: {
			string: bet.from.toString('hex'),
			buffer: bet.from,
		},
		ftAmount: new BigNumber(bet.ft_amt),
		hedger: {
			string: bet.hedger.toString('hex'),
			buffer: bet.hedger,
		},
		highPercent: new BigNumber(bet.high_perc),
		id: bet.id,
		itemId: {
			string: bet.item_id.toString('hex'),
			buffer: bet.item_id,
		},
		lowPercent: new BigNumber(bet.low_perc),
		isPaid: Boolean(bet.paid),
		startTime: bet.start_time,
		state: bet.state,
	}})

	// console.log('dnt.GetClientAccReqByItemAndAcc: ', res);

	return res;
} */

export const getAllClientBets = async (): Promise<iChrBet[]> => {
	const response = await (await getGtxClient()).query('dnt.GetAllClientAcc', {item_id: itemId});

	const res: iChrBet[] = response.map(bet => {return {
		endTime: bet.end_time,
		from: {
			string: bet.from.toString('hex'),
			buffer: bet.from,
		},
		ftAmount: new BigNumber(bet.ft_amt),
		hedger: {
			string: bet.hedger.toString('hex'),
			buffer: bet.hedger,
		},
		highPercent: new BigNumber(bet.high_perc),
		id: bet.id,
		itemId: {
			string: bet.item_id.toString('hex'),
			buffer: bet.item_id,
		},
		lowPercent: new BigNumber(bet.low_perc),
		isPaid: Boolean(bet.paid),
		startTime: bet.start_time,
		state: bet.state,
	}})

	console.log('dnt.GetAllClientAcc: ', res);

	return res;
}