import React, { lazy, Suspense } from "react";

import { useStoreSelector } from "../../../store/store";

import Loader from "../../UI/Loader/Loader";

const OrderModal = lazy(() => import('../OrderModal/OrderModal'));
const ApproveModal = lazy(() => import('../approve_modal/approve_modal'));
const InfoModal = lazy(() => import('../InfoModal/InfoModal'));
const HistoryModal = lazy(() => import('../HistoryModal/HistoryModal'));
const LossModal = lazy(() => import('../LossModal/LossModal'));
const WinModal = lazy(() => import('../WinModal/WinModal'));
const AboutModal = lazy(() => import('../AboutModal/AboutModal'));
const ContactModal = lazy(() => import('../ContactModal/ContactModal'));


const ModalsController: React.FC = () => {

	const { openedModals } = useStoreSelector(store => store.modals);


	return (
		<>
			{openedModals.map(modal => {
				switch (modal.name) {
					case 'order':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<OrderModal
									name={modal.name}
									modalId={modal.modalId}
								/>
							</Suspense>
						)
					;

					case 'approve':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<ApproveModal
									name={modal.name}
									modalId={modal.modalId}
									approvePromiseFork={modal.approvePromiseFork}
								/>
							</Suspense>
						)
					;

					case 'info':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<InfoModal
									name={modal.name}
									modalId={modal.modalId}
									title={modal.title}
									message={modal.message}
									type={modal.type}
								/>
							</Suspense>
						)
					;

					case 'history':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<HistoryModal
									name={modal.name}
									modalId={modal.modalId}
								/>
							</Suspense>
						)
					;
					
					case 'loss':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<LossModal
									name={modal.name}
									modalId={modal.modalId}
									touchPrice={modal.touchPrice}
									touchTime={modal.touchTime}
								/>
							</Suspense>
						)
					;

					case 'win':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<WinModal
									name={modal.name}
									modalId={modal.modalId}
								/>
							</Suspense>
						)
					;

					case 'about':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<AboutModal
									name={modal.name}
									modalId={modal.modalId}
								/>
							</Suspense>
						)
					;

					case 'contact':
						return (
							<Suspense
								key={modal.name + modal.modalId}
								fallback={<Loader/>}
							>
								<ContactModal
									name={modal.name}
									modalId={modal.modalId}
								/>
							</Suspense>
						)
					;

					default:
						return null
					;
				}
			})}
		</>
	)
}

export default ModalsController;