import { iModalsState, tModalName } from "../../interfaces/interfaces";
import { eModalsActionTypes, tModalsAction } from "./modalsTypes";

const initialState: iModalsState = {
	modalsIds: {
		order: 0,
		info: 0,
		approve: 0,
		history: 0,
		loss: 0,
		win: 0,
		about: 0,
		contact: 0,
	},
	openedModals: [],
}

export const modalsReducer = (state = initialState, action: tModalsAction): iModalsState => {
	switch (action.type){
	
		case eModalsActionTypes.CLEAR_STATE:
			return initialState;

		case eModalsActionTypes.OPEN_NEW_MODAL:
			// let modalsIdsCopy = structuredClone(state.modalsIds);    // JS should be updated
			let modalsIds: {[K in tModalName]: number} = Object.assign(state.modalsIds);
			modalsIds[action.payload.name] = state.modalsIds[action.payload.name] + 1;
			return {
				...state,
				openedModals: state.openedModals.concat({...action.payload, modalId: state.modalsIds[action.payload.name]}),
				modalsIds,
			};

		case eModalsActionTypes.CLOSE_MODAL:
			return {
				...state,
				openedModals: state.openedModals.filter(modal =>
					!(modal.name === action.payload.name &&
					modal.modalId === action.payload.id)
				)
			};
		
		default: return state;
	}
}