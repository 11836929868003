import { FC } from "react";
// import { useAccount } from "wagmi";
// import Web3 from "web3";

// import { iAppContract, iTokenContract } from "../../../interfaces";

// import { useStoreDispatch, useStoreSelector } from "../../../store/store";


const Web3Comp: FC = () => {

	// const { connector } = useAccount();
	// const { appContracts, tokenContracts, configs } = useStoreSelector(store => store.app);
	// const { setAppContract, setTokenContract } = useStoreDispatch();

	// const [isOnSet, setIsOnSet] = useState(false);

	/* useEffect(() => {
		if (connector){
			console.log('connector: ', connector.options.getProvider().on);
			connector.on('connect', ()=> console.log('1'));
			// setIsOnSet(true)
		}
	}, [connector]) */

	/* useEffect(() => {
		if (connector){
			(async function () {
				const provider = await (connector?.options?.getProvider? connector?.options?.getProvider() : connector?.getProvider());	// works too
				// const provider = connector.options.getProvider();
				if (!provider) {
					throw new Error();
				}

				if ((!appContracts.BUSD || !tokenContracts.BUSD) && configs.BUSD){
					getBUSDContracts(provider);
				}

				if ((!appContracts.USDT || !tokenContracts.USDT) && configs.USDT){
					getUSDTContracts(provider);
				}
			}());
		}
	// eslint-disable-next-line
	}, [tokenContracts, appContracts, connector, configs]) */

	/* const getBUSDContracts = async (provider: any) => {
		// console.log('getBUSDContracts');
		const BUSDconfig = configs.BUSD;
		if (!connector || !BUSDconfig) {
			throw new Error();
		}

		// console.log('provider: ', provider, '\nprovider.on: ', provider.on);
		// provider.on && provider.on('disconnect', ()=> console.log('1'));
		// connector.on('disconnect', ()=> console.log('2'));

		const web3 = new Web3(provider);

		let appContract: iAppContract = new web3.eth.Contract(await JSON.parse(BUSDconfig.dnt.abi), BUSDconfig.dnt.adr) as any;
		setAppContract('BUSD', appContract);
		// console.log('BUSD app contract: ', appContract);
		
		let tokenContract: iTokenContract = new web3.eth.Contract(await JSON.parse(BUSDconfig.tokens.tok_abi), BUSDconfig.dnt.f_tok.adr) as any;
		setTokenContract('BUSD', tokenContract);
		// console.log('BUSD token contract: ', tokenContract)
		// console.log('BUSD contracts set');
	} */

	/* const getUSDTContracts = async (provider: any) => {
		// console.log('getUSDTContracts');
		const USDTconfig = configs.USDT;
		if (!connector || !USDTconfig) {
			throw new Error();
		}


		const web3 = new Web3(provider);

		let appContract: iAppContract = new web3.eth.Contract(await JSON.parse(USDTconfig.dnt.abi), USDTconfig.dnt.adr) as any;
		setAppContract('USDT', appContract);
		// console.log('DNT contract: ', DNT_contract);
		
		let tokenContract: iTokenContract = new web3.eth.Contract(await JSON.parse(USDTconfig.tokens.tok_abi), USDTconfig.dnt.f_tok.adr) as any;
		setTokenContract('USDT', tokenContract);
		// console.log('USDT contracts set');
	} */


	return null;
}

export default Web3Comp;