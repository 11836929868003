import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { basename } from './settings';
import { store } from './store/store';

import App from './components/App/App';
import IndexWrapper from './components/IndexWrapper/IndexWrapper';

import './index.scss';


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<>
		{/* <React.StrictMode> */}
			<IndexWrapper>
				<Provider store={store}>
					<BrowserRouter basename={basename}>
						<App/>
					</BrowserRouter>
				</Provider>
			</IndexWrapper>
		{/* </React.StrictMode> */}
	</>
);