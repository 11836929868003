import { iUIState } from "../../interfaces/interfaces";
import { tUIAction, eUIActionTypes } from "./UITypes";

const initialState: iUIState = {
	userAddress: null,
	windowWidth: window.innerWidth,
	isBuyButtonBlocked: false,
	isWrongChain: false,
}

export const UIReducer = (state = initialState, action: tUIAction): iUIState => {
	switch (action.type){

		case eUIActionTypes.CLEAR_STATE:
			return initialState
		;

		case eUIActionTypes.SET_USER_ADDRESS:
			if (state.userAddress === action.payload) return state;
			return {...state, userAddress: action.payload}
		;

		case eUIActionTypes.SET_WINDOW_WIDTH:
			if (state.windowWidth === action.payload) return state;
			return {...state, windowWidth: action.payload}
		;

		case eUIActionTypes.SET_IS_BUY_BUTTON_BLOCKED:
			if (state.isBuyButtonBlocked === action.payload) return state;
			return {...state, isBuyButtonBlocked: action.payload}
		;

		case eUIActionTypes.SET_IS_WRONG_CHAIN:
			if (state.isWrongChain === action.payload) return state;
			return {...state, isWrongChain: action.payload}
		;

		default: return state;
	}
}