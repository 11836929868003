export enum eUIActionTypes {
	CLEAR_STATE = 'UI/CLEAR_STATE',
	SET_USER_ADDRESS = 'UI/SET_USER_ADDRESS',
	SET_WINDOW_WIDTH = 'UI/SET_WINDOW_WIDTH',
	SET_IS_BUY_BUTTON_BLOCKED = 'UI/SET_IS_BUY_BUTTON_BLOCKED',
	SET_IS_WRONG_CHAIN = 'UI/SET_IS_WRONG_CHAIN',
}

export type tUIAction =
	iClearStateAction |
	iSetUserAddressAction |
	iSetWindowWidthAction |
	iSetIsBuyButtonBlockedAction |
	iSetIsWrongChainAction
;

interface iClearStateAction {
	type: eUIActionTypes.CLEAR_STATE
}

interface iSetUserAddressAction {
	type: eUIActionTypes.SET_USER_ADDRESS,
	payload: string
}

interface iSetWindowWidthAction {
	type: eUIActionTypes.SET_WINDOW_WIDTH,
	payload: number
}

interface iSetIsBuyButtonBlockedAction {
	type: eUIActionTypes.SET_IS_BUY_BUTTON_BLOCKED,
	payload: boolean
}

interface iSetIsWrongChainAction {
	type: eUIActionTypes.SET_IS_WRONG_CHAIN,
	payload: boolean
}