import { Session } from "@chromia/ft4";

import { iChrBet, iChrOrder, iTypedGtxClient } from "../../interfaces/chrInterfaces";
import { tDispatch } from "../store";
import { eChromiaActionTypes } from "./chromiaTypes";

export const clearChrState = () => {
	return (dispatch: tDispatch) => {
		dispatch({
			type: eChromiaActionTypes.CLEAR_STATE,
		})
	}
}

export const setSession = (session: Session) => {
	return (dispatch: tDispatch) => {
		dispatch({
			type: eChromiaActionTypes.SET_SESSION,
			payload: session
		})
	}
}

export const setChrClientAddr = (address: string) => {
	return (dispatch: tDispatch) => {
		dispatch({
			type: eChromiaActionTypes.SET_CHR_USER_ADDR,
			payload: address
		})
	}
}

export const setGtxClient = (client: iTypedGtxClient) => {
	return (dispatch: tDispatch) => {
		dispatch({
			type: eChromiaActionTypes.SET_GTX_CLIENT,
			payload: client
		})
	}
}

export const setIsChrAccRegistered = (state: boolean) => {
	return (dispatch: tDispatch) => {
		dispatch({
			type: eChromiaActionTypes.SET_IS_CHR_ACC_REGISTERED,
			payload: state
		})
	}
}

export const setChrBestOrder = (order: iChrOrder | null) => {
	return (dispatch: tDispatch) => {
		dispatch({
			type: eChromiaActionTypes.SET_BEST_ORDER,
			payload: order
		})
	}
}

export const setLastChrBet = (bet: iChrBet | null) => {
	return (dispatch: tDispatch) => {
		dispatch({
			type: eChromiaActionTypes.SET_LAST_BET,
			payload: bet
		})
	}
}