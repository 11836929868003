import BN from "bn.js";

import { ETHval } from "../interfaces/interfaces";
import { fixExponential } from "../helpers";

/**
 * Returns BN from human-readable string or number (float possible) with passed decimals.
 * 
 * Default decimals: 18;
 */
const valToBN = (value: string | number, decimals?: number): BN => {
	if(
		Number.isNaN(+value) ||
		!Number.isFinite(+value) ||
		(typeof decimals === 'number' && (
			Number.isNaN(decimals) ||
			!Number.isFinite(decimals)
		))
	) throw new Error('Error code: 6_15');

	let dec = typeof decimals === 'number' ? decimals : 18;
	let arr = value.toString().split('.');

	if (dec === 0) return new BN(arr[0]);
	if (arr.length === 1) return new BN(arr[0] + '0'.repeat(dec));
	return new BN(arr[0] + arr[1].slice(0, dec).padEnd(dec, '0'));
}

/**
 * Returns a human-readable string of stringBN with passed decimals.
 * 
 * Default decimals: 18.
 */
const pseudoBNToVal = (value: string | number, decimals?: number): string => {
	if(Number.isNaN(value)) throw new Error('Error code: 6_16');

	let dec = typeof decimals === 'number'? decimals : 18;
	if (value === '0') return '0';
	let str = value.toString();
	let res = '';
	if (str[0] === '-') {
		res = '-';
		str = str.substring(1);
	}
	str = str.replace(/^0+/, '');
	if (str.length > dec) {
		res += str.substring(0, str.length - dec) + '.' + str.substring(str.length - dec);
	}
	else {
		res += '0.' + str.padStart(dec, '0');
	}
	res = res.replace(/0+$/, '');
	if (res[res.length - 1] === '.') return res.substring(0, res.length-1);
	// if (res[res.length - 1] === '.') return res + '0';
	return res;
}

interface iConstructorOptions {
	/**
	 * Decimals which will be used for construction.
	 * 
	 * Default: 0.
	 */
	currentDecimals?: number,
	/**
	 * Decimals which will be used for `getDecBN`.
	 * 
	 * Default: 18.
	 */
	decimals?: number,
	/**
	 * Decimals wich will be used for `valueStr`.
	 * 
	 * Default: unlimited.
	 */
	// stringDecimals?: number,
}

interface iOperationOptions {
	/**
	 * New BigNumber will have this decimals.
	 */
	newDecimals?: number,
	/**
	 * Raises value to power of 18.
	 * 
	 * Default: false.
	 */
	raiseValue?: boolean,
}


export class BigNumber {
	/**
	 * Value in BN with 18 decimals.
	 */
	valueBN18: BN;
	/**
	 * BN decimals (default 18).
	 */
	decimals: number;
	/**
	 * Human-readable string (with decimal part).
	 */
	valueStr: string;

	constructor (value: string | number | BN,
		/**
		 * Additional options (`currentDecimals`, `decimals`).
		 */
		options?: iConstructorOptions,
	){
		if (
			options && (
				(typeof options.decimals === 'number' && (!Number.isSafeInteger(options.decimals) || options.decimals > 255 || options.decimals < 0 )) ||
				(typeof options.currentDecimals === 'number' && (!Number.isSafeInteger(options.currentDecimals) || options.currentDecimals > 255 || options.currentDecimals < 0 ))
			)
		){
			throw new Error();
		}
		this.decimals = (options && typeof options.decimals === 'number')? options.decimals : 18;
		const _currDec: number = (options && typeof options.currentDecimals === 'number')? options.currentDecimals : 0;

		if (value instanceof BN){
			this.valueBN18 = value.mul( new BN(10).pow(new BN(18 - _currDec)) );
			this.valueStr = pseudoBNToVal( this.valueBN18.toString() );
			return;
		}

		if (typeof value === 'number'){
			if (Number.isNaN(value) || !Number.isFinite(value)){
				throw new Error();
			}
		}
		const _val = fixExponential(value);

		this.valueBN18 = valToBN(_val, 18 - _currDec);
		this.valueStr = pseudoBNToVal( this.valueBN18.toString() );
	}

	/**
	 * @returns BN with assigned decimals.
	 */
	getDecBN(): BN {
		return this.valueBN18.div( new BN(10).pow(new BN(18 - this.decimals)) );
	}

	/**
	 * Multiplies two values in BN with 18 decimals.
	 * @param value Multiplicator.
	 * @param options Additional options (`newDecimals`, `raiseValue`).
	 * @returns New BigNumber.
	 */
	mul(value: string | number | BN | BigNumber, options?: iOperationOptions): BigNumber {
		let mult: BN = (typeof value === 'number' || typeof value === 'string')
			? options?.raiseValue
				? valToBN(value)
				: new BN(value)
			: (value instanceof BN)
				? options?.raiseValue
					? value.mul(ETHval)
					: value
				: options?.raiseValue
					? value.valueBN18.mul(ETHval)
					: value.valueBN18
		;

		const res = this.valueBN18.mul(mult).div(ETHval);
		return new BigNumber(res, {currentDecimals: 18, decimals: options?.newDecimals});
	}

	/**
	 * Divides two values in BN with 18 decimals.
	 * @param value Divider.
	 * @param options Additional options (`newDecimals`, `raiseValue`).
	 * @returns New BigNumber.
	 */
	div(value: string | number | BN | BigNumber, options?: iOperationOptions): BigNumber {
		let divider: BN = (typeof value === 'number' || typeof value === 'string')
			? options?.raiseValue
				? valToBN(value)
				: new BN(value)
			: (value instanceof BN)
				? options?.raiseValue
					? value.mul(ETHval)
					: value
				: options?.raiseValue
					? value.valueBN18.mul(ETHval)
					: value.valueBN18
		;

		if (divider.eq(new BN('0'))) throw new Error('Division by zero');

		const res = this.valueBN18.mul(ETHval).div(divider);
		return new BigNumber(res, {currentDecimals: 18, decimals: options?.newDecimals});
	}

	/**
	 * Adds one value to another in BN with 18 decimals.
	 * @param value Value to add.
	 * @param options Additional options (`newDecimals`, `raiseValue`).
	 * @returns New BigNumber.
	 */
	add(value: string | number | BN | BigNumber, options?: iOperationOptions): BigNumber {
		let additional: BN = (typeof value === 'number' || typeof value === 'string')
			? options?.raiseValue
				? valToBN(value)
				: new BN(value)
			: (value instanceof BN)
				? options?.raiseValue
					? value.mul(ETHval)
					: value
				: options?.raiseValue
					? value.valueBN18.mul(ETHval)
					: value.valueBN18
		;

		const res = this.valueBN18.add(additional);
		return new BigNumber(res, {currentDecimals: 18, decimals: options?.newDecimals});
	}

	/**
	 * Subtracts value from another in BN with 18 decimals.
	 * @param value Subtrahend.
	 * @param options Additional options (`newDecimals`, `raiseValue`).
	 * @returns New BigNumber.
	 */
	sub(value: string | number | BN | BigNumber, options?: iOperationOptions): BigNumber {
		let subtrahend: BN = (typeof value === 'number' || typeof value === 'string')
			? options?.raiseValue
				? valToBN(value)
				: new BN(value)
			: (value instanceof BN)
				? options?.raiseValue
					? value.mul(ETHval)
					: value
				: options?.raiseValue
					? value.valueBN18.mul(ETHval)
					: value.valueBN18
		;

		const res = this.valueBN18.sub(subtrahend);
		return new BigNumber(res, {currentDecimals: 18, decimals: options?.newDecimals});
	}
}