import { legacy_createStore, compose, applyMiddleware, bindActionCreators } from 'redux';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';

import { iReduxState } from '../interfaces/interfaces';
import { rootReducer } from './rootReducer';
import * as appActionCreators from './app/appActions';
import * as modalsActionCreators from './modals/modalsActions';
import * as chromiaActionCreators from './chromia/chromiaActions';
import * as UIActionCreators from './UI/UIActions';

declare global{
	interface Window{
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : any;
	}
}

const composeEnhancers = (process.env.REACT_APP_production === 'false' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose ; 
 
export const store = legacy_createStore(rootReducer, composeEnhancers(
	applyMiddleware(thunk)
));

export type tDispatch = typeof store.dispatch;

export const useStoreDispatch = () => {		// combine all actions and wrapps it in dispatch 
	const dispatch = useDispatch();
	const allActionCreators = { 
		...appActionCreators,
		...modalsActionCreators,
		...chromiaActionCreators,
		...UIActionCreators,
	};
	return bindActionCreators(allActionCreators, dispatch);
}

export const useStoreSelector: TypedUseSelectorHook<iReduxState> = useSelector;	// gives access to redux store