import { iBet, iConfig, iAppContract, iOrder, iPrice, iTokenContract, tAvailableCurrencies, iClosedBet, iLastActiveBet, iLastInactiveBet } from "../../interfaces/interfaces";

export enum eAppActionTypes {
	CLEAR_STATE = 'APP/CLEAR_STATE',
	SET_API = 'APP/SET_API',
	SET_CONFIG = 'APP/SET_CONFIG',
	SET_IS_INITED = 'APP/SET_IS_INITED',
	SET_CURRENT_CURRENCY = 'APP/SET_CURRENT_CURRENCY',
	SET_TIMESTAMP = 'APP/SET_TIMESTAMP',
	SET_APP_CONTRACT = 'APP/SET_APP_CONTRACT',
	SET_TOKEN_CONTRACT = 'APP/SET_TOKEN_CONTRACT',
	SET_BEST_ORDER = 'APP/SET_BEST_ORDER',
	SET_CURRENT_BET = 'APP/SET_CURRENT_BET',
	SET_HISTORY_BETS = 'APP/SET_HISTORY_BETS',
	SET_LAST_BETS = 'APP/SET_LAST_BETS',
	SET_LAST_ORDER = 'APP/SET_LAST_ORDER',

	// TEMP
	SET_CURRENT_PRICE = 'APP/SET_CURRENT_PRICE',
}

export type tAppAction =
	iClearStateAction |
	iSetApiAction |
	iSetConfigAction |
	iSetIsInitedAction |
	iSetCurrentCurrencyAction |
	iSetTimestamp |
	iSetAppContractAction |
	iSetTokenContractAction |
	iSetBestOrderAction |
	iSetCurrentBetAction |
	iSetHistoryBetsAction |
	iSetLastBetsAction |
	iSetLastOrderAction |

	// temp
	iSetCurrentPriceAction
;

interface iClearStateAction {
	type: eAppActionTypes.CLEAR_STATE
}

interface iSetApiAction {
	type: eAppActionTypes.SET_API,
	payload: {
		currency: tAvailableCurrencies,
		api: string
	}
}

interface iSetConfigAction {
	type: eAppActionTypes.SET_CONFIG,
	payload: {
		currency: tAvailableCurrencies,
		config: iConfig
	}
}

interface iSetIsInitedAction {
	type: eAppActionTypes.SET_IS_INITED,
	payload: boolean
}

interface iSetCurrentCurrencyAction {
	type: eAppActionTypes.SET_CURRENT_CURRENCY,
	payload: tAvailableCurrencies
}

interface iSetTimestamp {
	type: eAppActionTypes.SET_TIMESTAMP,
	payload: number
}

interface iSetAppContractAction {
	type: eAppActionTypes.SET_APP_CONTRACT,
	payload: {
		contract: iAppContract,
		currency: tAvailableCurrencies
	}
}

interface iSetTokenContractAction {
	type: eAppActionTypes.SET_TOKEN_CONTRACT,
	payload: {
		contract: iTokenContract,
		currency: tAvailableCurrencies
	}
}

interface iSetBestOrderAction {
	type: eAppActionTypes.SET_BEST_ORDER,
	payload: {
		order: iOrder,
		currency: tAvailableCurrencies
	}
}

interface iSetCurrentBetAction {
	type: eAppActionTypes.SET_CURRENT_BET,
	payload: {
		bet: iBet,
		currency: tAvailableCurrencies
	}
}

interface iSetHistoryBetsAction {
	type: eAppActionTypes.SET_HISTORY_BETS,
	payload: {
		bets: iClosedBet[],
		currency: tAvailableCurrencies
	}
}

interface iSetLastBetsAction {
	type: eAppActionTypes.SET_LAST_BETS,
	payload: {
		currency: tAvailableCurrencies,
		bets: {
			activeBets: iLastActiveBet[],
			inactiveBets: iLastInactiveBet[]
		}
	}
}

interface iSetLastOrderAction {
	type: eAppActionTypes.SET_LAST_ORDER,
	payload: {
		currency: tAvailableCurrencies,
		bet: iClosedBet
	}
}

// TEMP
interface iSetCurrentPriceAction {
	type: eAppActionTypes.SET_CURRENT_PRICE,
	payload: iPrice
}