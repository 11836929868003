import { FC, useEffect } from "react";
// import { useTonAddress } from "@tonconnect/ui-react";

import { getHumanDate, getLocalStorageItem, setLocalStorageItem } from "../../../helpers";
import { iClosedBet, tAvailableCurrencies } from "../../../interfaces/interfaces";
import { useStoreDispatch, useStoreSelector } from "../../../store/store";
import { blockchain } from "../../../settings";
import { iChrBet } from "../../../interfaces/chrInterfaces";
import { BigNumber } from "../../../classes/BigNumber";


const LastOrderController: FC = () => {

	// const userAddress = useTonAddress();
	const { lastOrders, currentCurrency, apis } = useStoreSelector(store => store.app);
	const { userAddress } = useStoreSelector(store => store.UI);
	const { lastBet } = useStoreSelector(store => store.chromia);
	const { openNewModal } = useStoreDispatch();

	useEffect(() => {
		switch (blockchain) {
			case 'chromia':
				checkChrLoss(lastBet, currentCurrency);
				break;

			case 'ton':			
				checkLoss(lastOrders[currentCurrency], currentCurrency);
				break;
		
			default:
				break;
		}
		// eslint-disable-next-line
	}, [lastOrders, currentCurrency, userAddress, lastBet])

	const checkLoss = async (lastOrder: iClosedBet | undefined, currency: tAvailableCurrencies) => {
		if (!lastOrder || !userAddress) return;

		const storedLoss = await getLocalStorageItem({requestName: 'dnt_chr_last_order', currency, address: userAddress});

		if (storedLoss?.orderId === lastOrder.id) return;
		
		if (lastOrder.is_client_win === 1) return;	// bet in progress

		if (lastOrder.is_client_win === 3){
			openNewModal({
				name: 'win'
			})
		} else {	// 2
			openNewModal({
				name: 'loss',
				touchPrice: lastOrder.touch_val,
				touchTime: getHumanDate(lastOrder.touch_time, 'hh:mm:ss'),
			});
		}

		setLocalStorageItem({requestName: 'dnt_chr_last_order', currency, address: userAddress, orderId: lastOrder.id});
	}

	const checkChrLoss = async (lastBet: iChrBet | null, currency: tAvailableCurrencies) => {
		if (!lastBet || !userAddress) return;

		const storedLoss = await getLocalStorageItem({requestName: 'dnt_chr_last_order', currency, address: userAddress});

		if (storedLoss?.orderId === lastBet.id) return;
		
		if (lastBet.state !== 'fin_client_win' && lastBet.state !== 'fin_hedger_win') return;	// bet in progress

		if (lastBet.state === 'fin_client_win' && !lastBet.isPaid){
			openNewModal({
				name: 'win'
			})
		} else {	// hedger win
			if (!apis[currentCurrency])	return;	// throw error
			const response = await fetch(apis[currentCurrency] + '/client/closed_accounts_barriers?acc_id=' + lastBet.id);
			if (!response.ok){
				throw new Error();
			}
			const barriersData: {
				high_bar: string,	// number
				id: number,	// not acc_id
				min_price_time: number,	// ts
				max_price_time: number,	// ts
				min_price: string,	// number
				account_id: number,
				low_bar: string,	// number
				max_price: string	// number
			} = await response.json();
			// console.log('barriersData: ', barriersData);
			let touchPrice: string;
			let touchTime: number;
			if (new BigNumber(barriersData.max_price).valueBN18.gt(new BigNumber(barriersData.high_bar).valueBN18)){
				touchPrice = barriersData.max_price;
				touchTime = barriersData.max_price_time;
			} else {
				touchPrice = barriersData.min_price;
				touchTime = barriersData.min_price_time;
			}
			openNewModal({
				name: 'loss',
				touchPrice: touchPrice,
				touchTime: getHumanDate(touchTime, 'hh:mm:ss'),
			});
		}

		setLocalStorageItem({requestName: 'dnt_chr_last_order', currency, address: userAddress, orderId: lastBet.id});
	}

	return null;
}

export default LastOrderController;