import { iConfig, tAvailableCurrencies } from "./interfaces/interfaces";

type tDateFormat = 
	'YYYY-MM-DD' |
	'YYYY-MM-DD hh:mm' |
	'YYYY-MM-DD hh:mm:ss' |
	'DDMMMYY' |
	'DDmmmYY' |
	'mmm DD' |
	'YYYY.MM.DD' |
	'DD mmm YY, hh:mm' |
	'hh:mm' |
	'hh:mm:ss'
;
export const getHumanDate = (ts: number, format: tDateFormat): string => {
	if(Number.isNaN(ts)) throw new Error('Error code: 6_9');

	const date = new Date(ts * 1000);

	const year = date.getUTCFullYear().toString();
	const month = (date.getUTCMonth()+1).toString().padStart(2, '0');
	const day = date.getUTCDate().toString().padStart(2, '0');
	const hour = date.getUTCHours().toString().padStart(2, '0');
	const minutes = date.getUTCMinutes().toString().padStart(2, '0');
	const seconds = date.getUTCSeconds().toString().padStart(2, '0');

	const monthNumber = date.getUTCMonth();
	const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
	const monthsCapital = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];

	switch (format) {
		case 'YYYY-MM-DD':
			return `${year}-${month}-${day}`;

		case 'YYYY-MM-DD hh:mm':
			return `${year}-${month}-${day} ${hour}:${minutes}`;

		case 'YYYY-MM-DD hh:mm:ss':
			return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;

		case 'DDMMMYY':
			return `${day}${monthsCapital[monthNumber]}${year.slice(-2)}`;

		case 'DDmmmYY':
			return `${day}${months[monthNumber]}${year.slice(-2)}`;

		case 'mmm DD':
			return `${months[monthNumber]} ${day}`;

		case 'YYYY.MM.DD':
			return `${year}.${month}.${day}`;

		case 'DD mmm YY, hh:mm':
			return `${day} ${months[monthNumber]} ${year.slice(-2)}, ${hour}:${minutes}`;

		case 'hh:mm':
			return `${hour}:${minutes}`;

		case 'hh:mm:ss':
			return `${hour}:${minutes}:${seconds}`;

		default:
			return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
	}
}

export const getApi = async (apis: string[]): Promise<{api: string, config: iConfig} | undefined> => {
	if (apis.length === 0) throw new Error('Error code: 6_1');
	for (let i=0; i < apis.length; i++){
		try{
			let response = await fetch(apis[i] + '/info');
			if (response.ok) return { api: apis[i], config: await response.json() };
		} catch {}
	}
}

export const getApiWithBalancer = async (apis: string[]): Promise<{api: string, config: iConfig} | undefined> => {
	if (apis.length === 0) throw new Error('Error code: 6_1_b');
	let response = await Promise.any( apis.map(async (api) => {
		try {
			let response = await fetch(api + '/info');
			if (response.ok) return { api, response };
		} catch {}
	}) );

	return response? { api: response.api, config: await response.response.json() } : undefined;
}

export const fixExponential = (value: string | number): string => {
	const data = value.toString().split(/[eE]/);
	if (data.length === 1) return data[0];
  
	let z = '',
		sign = +value < 0 ? '-' : '',
		str = data[0].replace('.', ''),
		mag = Number(data[1]) + 1;
  
	if (mag < 0) {
		z = sign + '0.';
		while (mag++) z += '0';

		return z + str.replace(/^-/, '');
	}

	mag -= str.length;
	while (mag--) z += '0';

	return str + z;
}

/**
 * Works as default toFixed but not rounds.
*/
export function toFixed(value: number | string, digits: number): string{
	if (typeof(value) === 'number'){
		value = value.toString();
	}
	let splitted = value.split('.');
	if (splitted[1]){
		for (let i=1; i<digits; i++){
			splitted[1] = splitted[1] + '0';
		}
	}
	return splitted.length === 2
		? splitted[0] + '.' + splitted[1].substring(0,digits)
		: splitted[0]
	;
}

export const getRandomNumber = (min: number, max: number) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

const localStorageNames = {
	lastOrder: 'dnt_chr_last_order',
	lastCurrency: 'dnt_chr_last_currency',
} as const;
type tGetLocalStorageItem = {
	(props: tGetLocalStorageLastLoss): Promise<{orderId: number} | undefined>,
	(props: tGetLocalStorageLastCurrency): Promise<tAvailableCurrencies | undefined>
}
type tGetLocalStorageLastLoss = {
	requestName: typeof localStorageNames.lastOrder,
	currency: tAvailableCurrencies,
	address: string,
}
type tGetLocalStorageLastCurrency = {
	requestName: typeof localStorageNames.lastCurrency,
}
export const getLocalStorageItem: tGetLocalStorageItem = async (props) => {
	switch (props.requestName) {
		case (localStorageNames.lastOrder):

			const orderId = localStorage.getItem(`${props.requestName}_${props.currency}_${props.address}`);
			// add type check
			// let lastOrder : {ts: number; checked: boolean} = await JSON.parse(lsItem);
			return orderId && await JSON.parse(orderId);
		;

		case (localStorageNames.lastCurrency):
			const currency = localStorage.getItem(`${props.requestName}`);
			// add type check
			// let lastOrder : {ts: number; checked: boolean} = await JSON.parse(lsItem);
			return currency && await JSON.parse(currency);
		;
	}
}

type tSetLocalStorageItem = {
	(props: tGetLocalStorageLastLoss & {orderId: number}): void,
	(props: tGetLocalStorageLastCurrency & {currency: tAvailableCurrencies}): void,
}
export const setLocalStorageItem: tSetLocalStorageItem = (props) => {
	switch (props.requestName) {
		case (localStorageNames.lastOrder):
			localStorage.setItem(`${props.requestName}_${props.currency}_${props.address}`, JSON.stringify({orderId: props.orderId}));
			break
		;

		case (localStorageNames.lastCurrency):
			localStorage.setItem(`${props.requestName}`, JSON.stringify(props.currency));
			break
		;
	}
}