import { emptyBet, emptyOrder, iAppState, iBet, iOrder, tAvailableCurrencies } from "../../interfaces/interfaces";
import { availableCurrencies } from "../../settings";
import { tAppAction, eAppActionTypes } from "./appTypes";

const initialState: iAppState = {
	// apis: availableCurrencies.reduce((accum, val) => ({...accum, [val as tAvailableCurrencies]: null}), {}) as {[key in tAvailableCurrencies]: null},
	apis: Object.fromEntries(availableCurrencies.map(currency => [currency, undefined])) as {[key in tAvailableCurrencies]: undefined},
	configs: Object.fromEntries(availableCurrencies.map(currency => [currency, undefined])) as {[key in tAvailableCurrencies]: undefined},
	isInited: false,
	currentCurrency: 'USDT',
	timestamp: null,
	appContracts: Object.fromEntries(availableCurrencies.map(currency => [currency, undefined])) as {[key in tAvailableCurrencies]: undefined},
	tokenContracts: Object.fromEntries(availableCurrencies.map(currency => [currency, undefined])) as {[key in tAvailableCurrencies]: undefined},
	/* bestOrders: {
		// TON: undefined,
		USDT: emptyOrder,	// temp
	}, */
	bestOrders: Object.fromEntries(availableCurrencies.map(currency => [currency, emptyOrder])) as {[key in tAvailableCurrencies]: iOrder},
	/* currentBets: {
		// TON: undefined,
		USDT: emptyBet,	//  temp
	}, */
	currentBets: Object.fromEntries(availableCurrencies.map(currency => [currency, emptyBet])) as {[key in tAvailableCurrencies]: iBet},
	historyBets: Object.fromEntries(availableCurrencies.map(currency => [currency, []])) as {[key in tAvailableCurrencies]: []},
	/* lastBets: {
		USDT: {
			activeBets: [],
			inactiveBets: []
		},
	}, */
	lastBets: Object.fromEntries(availableCurrencies.map(currency => [currency, {activeBets: [], inactiveBets: []}])) as {[key in tAvailableCurrencies]: {activeBets: [], inactiveBets: []}},
	lastOrders: Object.fromEntries(availableCurrencies.map(currency => [currency, undefined])) as {[key in tAvailableCurrencies]: undefined},

	// temp zone
	currentPrice: null,
}

export const appReducer = ( state = initialState, action: tAppAction) : iAppState => {
	switch (action.type){

		case eAppActionTypes.CLEAR_STATE:
			return {
				...state,
				apis: state.apis,
				configs: state.configs,
				isInited: state.isInited,
				currentCurrency: state.currentCurrency,
			}
		;

		case eAppActionTypes.SET_API:
			const newApis = {...state.apis};
			newApis[action.payload.currency] = action.payload.api;
			return {...state, apis: newApis}
		;

		case eAppActionTypes.SET_CONFIG:
			const newConfigs = {...state.configs};
			newConfigs[action.payload.currency] = action.payload.config;
			return {...state, configs: newConfigs}
		;

		case eAppActionTypes.SET_IS_INITED:
			return {...state, isInited: action.payload}
		;

		case eAppActionTypes.SET_CURRENT_CURRENCY:
			return state;	// temp
			// if (state.currentCurrency === action.payload) return state;
			// return {...state, currentCurrency: action.payload}
		;

		case eAppActionTypes.SET_TIMESTAMP:
			return {...state, timestamp: action.payload}
		;

		case eAppActionTypes.SET_APP_CONTRACT:
			const newAppContracts = {...state.appContracts};
			newAppContracts[action.payload.currency] = action.payload.contract;
			return {...state, appContracts: newAppContracts}
		;

		case eAppActionTypes.SET_TOKEN_CONTRACT:
			const newTokenContracts = {...state.tokenContracts};
			newTokenContracts[action.payload.currency] = action.payload.contract;
			return {...state, tokenContracts: newTokenContracts}
		;

		case eAppActionTypes.SET_BEST_ORDER:
			if (state.bestOrders[action.payload.currency].id === action.payload.order.id) return state;
			const newBestOrders = {...state.bestOrders};
			newBestOrders[action.payload.currency] = action.payload.order;
			return {...state, bestOrders: newBestOrders}
		;
	
		case eAppActionTypes.SET_CURRENT_BET:
			if (state.currentBets[action.payload.currency].id === action.payload.bet.id) return state;
			const newCurrentBets = {...state.currentBets};
			newCurrentBets[action.payload.currency] = action.payload.bet;
			return {...state, currentBets: newCurrentBets}
		;
		
		case eAppActionTypes.SET_HISTORY_BETS:
			return {...state, historyBets: {...state.historyBets, [action.payload.currency]: action.payload.bets}}
		;
		
		case eAppActionTypes.SET_LAST_BETS:
			const newLastBets = {...state.lastBets};
			newLastBets[action.payload.currency] = action.payload.bets;
			return {...state, lastBets: newLastBets}
		;

		case eAppActionTypes.SET_LAST_ORDER:
			const stateOrder = state.lastOrders[action.payload.currency];
			if (
				stateOrder?.id === action.payload.bet.id &&
				stateOrder?.is_client_win === action.payload.bet.is_client_win &&
				stateOrder?.touch_val === action.payload.bet.touch_val &&
				stateOrder?.touch_time === action.payload.bet.touch_time
			) return state;
			const newLastOrders = {...state.lastOrders};
			newLastOrders[action.payload.currency] = action.payload.bet;
			return {...state, lastOrders: newLastOrders}
		;

		// TEMP
		case eAppActionTypes.SET_CURRENT_PRICE:
			return {...state, currentPrice: action.payload}
		;

		default: return state;
	}
}