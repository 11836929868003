import { combineReducers } from "redux";

import { appReducer } from "./app/appReducer";
import { modalsReducer } from "./modals/modalsReducer";
import { chromiaReducer } from "./chromia/chromiaReducer";
import { UIReducer } from "./UI/UIReducer";

export const rootReducer = combineReducers({
	app: appReducer,
	modals: modalsReducer,
	chromia: chromiaReducer,
	UI: UIReducer
});