import { Session } from "@chromia/ft4";

import { iChrBet, iChrOrder, iTypedGtxClient } from "../../interfaces/chrInterfaces";

export enum eChromiaActionTypes {
	CLEAR_STATE = 'CHR/CLEAR_STATE',
	SET_SESSION = 'CHR/SET_SESSION',
	SET_CHR_USER_ADDR = 'CHR/SET_CHR_USER_ADDR',
	SET_GTX_CLIENT = 'CHR/SET_GTX_CLIENT',
	SET_IS_CHR_ACC_REGISTERED = 'CHR/SET_IS_CHR_ACC_REGISTERED',

	// temp
	SET_BEST_ORDER = 'CHR/SET_BEST_ORDER',
	SET_LAST_BET = 'CHR/SET_LAST_BET',
}

export type tChromiaAction =
	iClearStateAction |
	iSetSessionAction |
	iSetChrClientAddrAction |
	iSetGtxClientAction |
	iSetIsChrRegisteredAction |
	iSetBestOrderAction |
	iSetLastBetAction
;

interface iClearStateAction {
	type: eChromiaActionTypes.CLEAR_STATE
}

interface iSetSessionAction {
	type: eChromiaActionTypes.SET_SESSION,
	payload: Session
}

interface iSetChrClientAddrAction {
	type: eChromiaActionTypes.SET_CHR_USER_ADDR,
	payload: string
}

interface iSetGtxClientAction {
	type: eChromiaActionTypes.SET_GTX_CLIENT,
	payload: iTypedGtxClient
}

interface iSetIsChrRegisteredAction {
	type: eChromiaActionTypes.SET_IS_CHR_ACC_REGISTERED,
	payload: boolean
}

interface iSetBestOrderAction {
	type: eChromiaActionTypes.SET_BEST_ORDER,
	payload: iChrOrder | null
}

interface iSetLastBetAction {
	type: eChromiaActionTypes.SET_LAST_BET,
	payload: iChrBet | null
}