import { iChromiaState } from "../../interfaces/chrInterfaces";
import { eChromiaActionTypes, tChromiaAction } from "./chromiaTypes";

const initialState: iChromiaState = {
	userAddress: null,
	gtxClient: null,
	session: null,
	isChrAccRegistered: true,

	//temp
	bestOrder: null,
	lastBet: null,
}

export const chromiaReducer = (state = initialState, action: tChromiaAction): iChromiaState => {
	switch (action.type){
	
		case eChromiaActionTypes.CLEAR_STATE:
			return initialState;
		;

		case eChromiaActionTypes.SET_SESSION:
			return {...state, session: action.payload}
		;

		case eChromiaActionTypes.SET_CHR_USER_ADDR:
			return {...state, userAddress: action.payload}
		;

		case eChromiaActionTypes.SET_GTX_CLIENT:
			return {...state, gtxClient: action.payload}
		;

		case eChromiaActionTypes.SET_IS_CHR_ACC_REGISTERED:
			return {...state, isChrAccRegistered: action.payload}
		;

		case eChromiaActionTypes.SET_BEST_ORDER:
			return {...state, bestOrder: action.payload}
		;

		case eChromiaActionTypes.SET_LAST_BET:
			return {...state, lastBet: action.payload}
		;

		default: return state;
	}
}