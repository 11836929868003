import { FC, useEffect } from "react";

import { useStoreDispatch } from "../../../store/store";


const WindowWidthController: FC = () => {

	const { setWindowWidth } = useStoreDispatch();

	useEffect(() => {
		window.addEventListener('resize', resizeFunc)
		// console.log('window width: ', window.innerWidth);
		// eslint-disable-next-line
	}, [])
	
	const resizeFunc = () => {
		// console.log('resize', e.view?.innerWidth);
		// console.log('resize', e.);
		// console.log('resize', window.innerWidth);
		setWindowWidth(window.innerWidth);
	}


	return null;
}

export default WindowWidthController;