import { CHAIN } from "@tonconnect/ui-react";

// export const chainId: '0x38' | '0x61' = '0x61';	// 56 | 97
// export const chainId: CHAIN = CHAIN.MAINNET;	//
export const chainId: CHAIN = CHAIN.TESTNET;	//
// export const basename = '/dnt_v2_tn';
// export const basename = '/dnt_v2';
export const basename = '/dapp/';
export const version: string = '0.5';

// export const availableCurrencies = ['TON'] as const;
// export const availableCurrencies = ['USDT', 'BUSD'] as const;
export const availableCurrencies = ['USDT'] as const;

export const blockchain: 'binance' | 'chromia' | 'ton' = 'chromia';
export const availableBlockchains = ['binance', 'chromia', 'ton'] as const;

export const uTokenCurrencies: {[key in typeof availableBlockchains[number]]: string} = {
	binance: 'BTC',
	chromia: 'BTC',
	ton: 'SPY'
}

export const itemId = "604eb251da705e2554bb9789def1ebe4c3e51d796ef1a62a7835a8181a968e24";

export const chromiaBlockchainRID = '4AEB5DE8F71A6D6614CCAC582B45931A8A2FB94440810945132EEE3BD231A688';	// quest net
// export const chromiaBlockchainRID = 'F048F5E8BAD50DB9B04D22E245FB51F0D0E5874F8AFF9FBE050437947712CE71';	// dev net
// export const chromiaBlockchainRID = '8AE48FB9662A3DA58B1E5281BEBE1B3BF5AE1B0D1F59A9867DB8D5DC2971D24D';	// local
/* export const chromiaBlockchainUrl = [	// dev
	"https://node4.devnet1.chromia.dev:7740",
	"https://node5.devnet1.chromia.dev:7740",
	"https://node6.devnet1.chromia.dev:7740"
	// "https://node7.devnet1.chromia.dev:7740",
]; */
export const chromiaBlockchainUrl = [	// quest
    "https://node0.questnet.chromia.dev:7740",
    "https://node1.questnet.chromia.dev:7740",
    "https://node2.questnet.chromia.dev:7740",
    "https://node3.questnet.chromia.dev:7740"
]
// export const chromiaBlockchainUrl = "http://chromia.techno-content.com:7740";	// local

export const chromiaBackendUrl = 'https://chromia.techno-content.com:10443/dex_backend_chr_tn';	// questnet registration