import { tDispatch } from "../store"
import { eUIActionTypes } from "./UITypes"

export const clearUIState = () => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eUIActionTypes.CLEAR_STATE,
		})
	}
}

export const setUserAddress = (address: string) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eUIActionTypes.SET_USER_ADDRESS,
			payload: address
		})
	}
}

export const setWindowWidth = (width: number) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eUIActionTypes.SET_WINDOW_WIDTH,
			payload: width
		})
	}
}

export const setIsBuyButtonBlocked = (state: boolean) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eUIActionTypes.SET_IS_BUY_BUTTON_BLOCKED,
			payload: state
		})
	}
}

export const setIsWrongChain = (state: boolean) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eUIActionTypes.SET_IS_WRONG_CHAIN,
			payload: state
		})
	}
}