import { FC, useEffect, useState } from "react";

import { getApi, getLocalStorageItem } from "../../../helpers";
import { useStoreDispatch } from "../../../store/store";
import { blockchain } from "../../../settings";
import { getItemsQuery } from "../../../utils/chromia/queries";

import './StartUpPage.scss';


const StartUpPage: FC = () => {

	const { setIsInited, setApi, setConfig, setCurrentCurrency } = useStoreDispatch();
	const [isTONConfigInited, setIsTONConfigInited] = useState(false);
	const [isCHRConfigInited, setIsCHRConfigInited] = useState(false);
	const [isLSInited, setIsLSInited] = useState(false);

	useEffect(() => {
		if (isTONConfigInited && isLSInited) {
			setIsInited(true);
		} 
		if (isCHRConfigInited) {
			setIsInited(true)
		}
		// eslint-disable-next-line
	}, [isTONConfigInited, isLSInited, isCHRConfigInited]);

	useEffect(() => {
		switch (blockchain) {
			case 'chromia':
				getItemsQuery();
				getChrConfig();
				break;
		
			case 'ton':
				getTONConfig();
				break;

			default:
				break;
		}
		initLocalStorage();
		// eslint-disable-next-line
	}, []);
	
	const getChrConfig = async () => {
		const CHRapi = process.env.REACT_APP_CHR_API;
		const CHRapi2 = process.env.REACT_APP_CHR_API_RESERVE;

		const CHRapis: string[] = [];
		CHRapi && CHRapis.push(CHRapi);
		CHRapi2 && CHRapis.push(CHRapi2);

		if (CHRapis.length === 0){
			// set_is_network_troubles(true);
			// set_is_loading(false);
			return;
		}

		let CHRapiRes = await getApi(CHRapis);
		if (!CHRapiRes){
			throw new Error();
		}

		// setApi('TON', TONapiRes.api);
		// setConfig('TON', TONapiRes.config);
		setApi('USDT', CHRapiRes.api);
		setConfig('USDT', CHRapiRes.config);
		setIsCHRConfigInited(true);
	}

	const getTONConfig = async () => {
		const TONapi = process.env.REACT_APP_TON_API;
		const TONapi2 = process.env.REACT_APP_TON_API_RESERVE;

		const TONapis: string[] = [];
		TONapi && TONapis.push(TONapi);
		TONapi2 && TONapis.push(TONapi2);

		if (TONapis.length === 0){
			// set_is_network_troubles(true);
			// set_is_loading(false);
			return;
		}

		let TONapiRes = await getApi(TONapis);
		if (!TONapiRes){
			throw new Error();
		}

		// setApi('TON', TONapiRes.api);
		// setConfig('TON', TONapiRes.config);
		setApi('USDT', TONapiRes.api);
		setConfig('USDT', TONapiRes.config);
		setIsInited(true);
	}

	const initLocalStorage = async () => {
		const savedCurrency = await getLocalStorageItem({requestName: 'dnt_chr_last_currency'});
		if (savedCurrency) setCurrentCurrency(savedCurrency);
		setIsLSInited(true);
	}


	return (
		<div
			className="_StartUpPage"
		>
			Loading...
		</div>
	)
}

export default StartUpPage;