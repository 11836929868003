import { iApproveModal, iInfoModal, iLossModal, iSimpleModal, tModalName } from "../../interfaces/interfaces"
import { tDispatch } from "../store"
import { eModalsActionTypes } from "./modalsTypes"

export const clearModalsState = () => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eModalsActionTypes.CLEAR_STATE,
		})
	}
}

export const openNewModal = (modal:
	Omit<iSimpleModal, 'modalId'> |
	Omit<iInfoModal, 'modalId'> |
	Omit<iApproveModal, 'modalId'> |
	Omit<iLossModal, 'modalId'>
) => {
	return (dispatch : tDispatch) => { 
		dispatch({
			type: eModalsActionTypes.OPEN_NEW_MODAL,
			payload: modal
		})
	}
}

export const closeModal = (name: tModalName, id: number) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eModalsActionTypes.CLOSE_MODAL,
			payload: {name, id}
		})
	}
}