import { FC, PropsWithChildren, Suspense, lazy } from "react";

import { blockchain } from "../../settings";

import Spinner from "../UI/Spinner/Spinner";

const TonConfig = lazy(() => import('./TonConfig'));
const BscConfig = lazy(() => import('./BscConfig'));


const IndexWrapper: FC<PropsWithChildren> = ({children}) => {
	

	switch (blockchain) {
		case 'binance':
		case 'chromia':
			return (
				<Suspense
					fallback={<Spinner/>}
				>
					<BscConfig>
						{children}
					</BscConfig>
				</Suspense>
			)
		;

		case 'ton':
			return (
				<Suspense
					fallback={<Spinner/>}
				>
					<TonConfig>
						{children}
					</TonConfig>
				</Suspense>
			)
		;

		default: throw new Error();
	}
}

export default IndexWrapper;