import { iApproveModal, iInfoModal, iLossModal, iSimpleModal, tModalName } from "../../interfaces/interfaces";

export enum eModalsActionTypes {
	CLEAR_STATE = 'MODALS/CLEAR_STATE',
	OPEN_NEW_MODAL = 'MODALS/OPEN_NEW_MODAL',
	CLOSE_MODAL = 'MODALS/CLOSE_MODAL',
}

export type tModalsAction =
	iClearModalsState |
	iOpenNewModal |
	iCloseModal
;

interface iClearModalsState {
	type: eModalsActionTypes.CLEAR_STATE
}
interface iOpenNewModal {
	type: eModalsActionTypes.OPEN_NEW_MODAL,
	payload:
		Omit<iSimpleModal, 'modalId'> |
		Omit<iInfoModal, 'modalId'> |
		Omit<iApproveModal, 'modalId'> |
		Omit<iLossModal, 'modalId'>
}

interface iCloseModal {
	type: eModalsActionTypes.CLOSE_MODAL,
	payload: {name: tModalName, id: number}
}