import { FC } from "react";


const WalletIcon: FC = () => {

	return (
		<div>

		</div>
	)
}

export default WalletIcon;