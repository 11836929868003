import BN from "bn.js";

import { availableCurrencies } from "../settings";
import { iChromiaState } from "./chrInterfaces";

// REDUX

export interface iReduxState {
	app: iAppState,
	modals: iModalsState,
	chromia: iChromiaState,
	UI: iUIState,
}

export interface iAppState {
	apis: {
		// [currency: string]: string | undefined,
		[key in tAvailableCurrencies]: string | undefined;

	},
	configs: {
		// [currency: string]: iConfig | undefined,
		[key in tAvailableCurrencies]: iConfig | undefined;
	},
	isInited: boolean,
	currentCurrency: tAvailableCurrencies,
	timestamp: number | null,
	appContracts: {
		[key in tAvailableCurrencies]: iAppContract | undefined;
	},
	tokenContracts: {
		[key in tAvailableCurrencies]: iTokenContract | undefined;
	},
	bestOrders: {
		// [key in tAvailableCurrencies]: iOrder | undefined;
		[key in tAvailableCurrencies]: iOrder;	// temp
	},
	currentBets: {
		// [key in tAvailableCurrencies]: iBet | undefined;
		[key in tAvailableCurrencies]: iBet;	// temp
	},
	historyBets: {
		[key in tAvailableCurrencies]: iClosedBet[];
	},
	lastBets: {
		[key in tAvailableCurrencies]: {
			activeBets: iLastActiveBet[],
			inactiveBets: iLastInactiveBet[],
		};
	},
	lastOrders: {
		[key in tAvailableCurrencies]: iClosedBet | undefined;
	},

	// temp zone
	currentPrice: iPrice | null,
}

export interface iModalsState {
	modalsIds: {[K in tModalName] :number},
	// openedModals: (iSimpleModal | iInfoModal | iTokenSelectModal | iLPTReturnModal | iConfirmModal | iChartModal | iTransactionModal | iFarmingStakeModal | iFarmingUnstakeModal)[],
	openedModals: (iSimpleModal | iInfoModal | iApproveModal | iLossModal)[],
}

export interface iUIState {
	/**
	 * Address to show.
	 */
	userAddress: string | null,
	windowWidth: number,
	/**
	 * Used while transaction in process.
	 */
	isBuyButtonBlocked: boolean,
	isWrongChain: boolean,
}

// /REDUX

// export type tAvailableCurrencies = 'USDT' | 'BUSD';
// export type tAvailableCurrencies = 'TON';
export type tAvailableCurrencies = typeof availableCurrencies[number];

export interface iConfig {
	dnt: {
		abi: string,
		adr: string,
		f_tok: iConfigToken,
		// u_tok: iConfigToken,	// not used now
	},
	net: {
		chainID: string,
		httpProvider: string,
	},
	tokens: {
		// [tokenAddress as string]: iConfigToken,
		tok_abi: string,
	}
}

interface iConfigToken {
	adr: string,
	dec: number,
	name: string,
	show_dec: number,
}

export interface iAppContract {
	methods: {
		ClientPayout: (id: number) => iSendMethod,
		TakeOrder: (order_id: number, ft_amount: BN) => iSendMethod,
	}
}

export interface iTokenContract {
	methods: {
		allowance: (client_addr: string, rfq_contract_addr: string) => { call: () => Promise<string> },
		balanceOf: (client_addr: string) => { call: () => Promise<string> },
		approve: (rfq_contract_addr: string, amount: BN | string) => iSendMethod,
		totalSupply: () => { call: () => Promise<number> },
	}
}

interface iSendMethod {
	send: (options: {
		from: string,
		gasPrice?: string,  // The gas price in wei to use for this transaction
		gas?: number,   // The maximum gas provided for this transaction (gas limit)
		chain?: any,
	}) => iPromiEvent<iTransactionReceipt>
}

export interface iWeb3Provider {   // different objects for different wallets
	isMetaMask?: boolean,
	disconnect?: () => void,
	on?: (event: string, callback: Function) => Function,
	once?: (event: string, callback: Function) => Function,
	// off: (event: string, callback?: Function) => void,
	// clearCachedProvider: () => void,
}

export interface iPromiseFork {
	resolve: null | ( (value: unknown) => void ) ,
	reject: null | ( (value: unknown) => void ) ,
}

export interface iOrder{
	id: number;
	hedger: string;
	duration: number;
	ft_amt: string;
	min_perc: string;
	low_perc: string;
	high_perc: string;
}

export interface iBet{
	id: number;
	// account_id: number;
	// order_id: number;
	address: string;
	// hedger: string;
	ft_amt: string;
	start_time: number;
	end_time: number;
	// low_bar: string;
	// high_bar: string;
	low_perc: string;
	high_perc: string;
}

export interface iPrice{
	time: number;
	price: number;
}

export interface iClosedBet{
	// id: number,
	id: number,
	// account_id: number,
	// order_id: number,
	address: string,
	// hedger: string,
	ft_amt: string,
	start_time: number,
	end_time: number,
	// low_bar: string,
	low_perc: string,
	// high_bar: string,
	high_perc: string,
	// is_client_win: boolean,
	is_client_win: 1 | 2 | 3,	// 1 - in progress, 2 - loss, 3 - win
	touch_time: number,	// ts
	touch_val: string
}

export interface iLeader{
	adr: string,
	net_winnings: number,
	rounds_played: number,
	rounds_won: number,
	winrate: string,
}

export const emptyOrder: iOrder = {
	id: -1,
	hedger: '',
	duration: 300,
	ft_amt: '0',
	min_perc: '0',
	low_perc: '0',
	high_perc: '0',
}

export const emptyBet: iBet = {
	id: -1,
	// id: 0,
	// account_id: 0,
	// order_id: -1,
	// order_id: 0,
	address: '',
	// hedger: '',
	ft_amt: '0',
	start_time: 0,
	end_time: 0,
	// low_bar: '',
	// high_bar: '',
	low_perc: '',
	high_perc: '',
}

export interface iPromiEvent<T> extends Promise<T> {
	once(
		type: 'sending',
		handler: (payload: iTransactionPayload) => void
	): iPromiEvent<T>;

	once(
		type: 'sent',
		handler: (payload: iTransactionPayload) => void
	): iPromiEvent<T>;

	once(
		type: 'transactionHash',
		handler: (transactionHash: string) => void
	): iPromiEvent<T>;

	once(
		type: 'receipt',
		handler: (receipt: iTransactionReceipt) => void
	): iPromiEvent<T>;

	once(
		type: 'confirmation',
		handler: (confirmationNumber: number, receipt: iTransactionReceipt, latestBlockHash?: string) => void
	): iPromiEvent<T>;

	once(type: 'error', handler: (error: iTransactionError) => void): iPromiEvent<T>;

	/* once(
		type: 'error' | 'confirmation' | 'receipt' | 'transactionHash' | 'sent' | 'sending',
		handler: (error: iTransactionError | iTransactionReceipt | string | object) => void
	): iPromiEvent<T>; */

	on(
		type: 'sending',
		handler: (payload: iTransactionPayload) => void
	): iPromiEvent<T>;

	on(
		type: 'sent',
		handler: (payload: iTransactionPayload) => void
	): iPromiEvent<T>;

	on(
		type: 'transactionHash',
		handler: (receipt: string) => void
	): iPromiEvent<T>;

	on(
		type: 'receipt',
		handler: (receipt: iTransactionReceipt) => void
	): iPromiEvent<T>;

	on(
		type: 'confirmation',
		handler: (confNumber: number, receipt: iTransactionReceipt, latestBlockHash?: string) => void
	): iPromiEvent<T>;

	on(type: 'error', handler: (error: iTransactionError) => void): iPromiEvent<T>;

	/* on(
		type: 'error' | 'confirmation' | 'receipt' | 'transactionHash' | 'sent' | 'sending',
		handler: (error: iTransactionError | iTransactionReceipt | string | object) => void
	): iPromiEvent<T>; */
}
export interface iTransactionReceipt {
	transactionHash: string,
	transactionIndex: number,
	blockHash: string,
	blockNumber: number,
	contractAddress?: string,
	cumulativeGasUsed: number,
	gasUsed: number,
	events: iTransactionEvent[]
	effectiveGasPrice: number,	// price (e.g. 10) * 10**9
	status: boolean;
	from: string;
	to: string;
	// logs: Log[];
	// logsBloom: string;
}
/* export interface Log {
	address: string;
	data: string;
	topics: string[];
	logIndex: number;
	transactionIndex: number;
	transactionHash: string;
	blockHash: string;
	blockNumber: number;
} */
export interface iTransactionError {
	/**
	 * 4001 - User denied transaction signature.
	 */
	code: number,
	message: string,
	stack: string,
}
interface iTransactionPayload {
	// callback
	method: string, // eth_sendTransaction
	params: {
		data: string,   // undefined -?
		from: string,
		gas: undefined, // string - ?
		gasPrice: string,	// undefined - ?
		to: string,
	}[]
}
interface iTransactionEvent {
	address: string,
	blockHash: string,
	blockNumber: number,
	event: undefined,	// ???
	id: string,
	logIndex: number,
	raw: {
		data: string,
		topics: string[],
	}
	removed: boolean,
	// returnValues: u {},	// ???
	signature: null,	// ??
	transactionHash: string,
	transactionIndex: number,
}

export const ETHval = new BN('1000000000000000000');	// 10**18

export type tModalName = 'order' | 'info' | 'approve' | 'history' | 'loss' | 'win' | 'about' | 'contact';

export interface iSimpleModal {
	name: Extract<tModalName, 'order' | 'history' | 'win' | 'about' | 'contact'>,
	modalId: number,
}

export interface iApproveModal {
	name: Extract<tModalName, 'approve'>,
	modalId: number,
	approvePromiseFork: iPromiseFork | undefined,
}

export interface iInfoModal {
	name: Extract<tModalName, 'info'>,
	modalId: number,
	message: string | null,
	title: string | undefined,
	type: 'success' | 'warning' | undefined,
}

export interface iLossModal {
	name: Extract<tModalName, 'loss'>,
	modalId: number,
	touchPrice: string,
	touchTime: string
}

export interface iLastActiveBet {
	client: string,
	ft_amt: number,
	start_time: number,	// ts
	end_time: number,	// ts
}

export interface iLastInactiveBet {
	client: string,
	ft_amt: number,
	start_time: number,	// ts
	end_time: number,	// ts
	is_client_win: boolean | null,
}