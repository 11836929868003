import { FC, useEffect, useState } from "react";

import { useStoreSelector } from "../../store/store";

import './AppInfo.scss';


const AppInfo: FC = () => {

	const { timestamp } = useStoreSelector(store => store.app);

	const [time, setTime] = useState('')

	useEffect(() => {
		if (!timestamp){
			setTime('');
			return;
		}
		let time = new Date(timestamp);
		setTime(('0' + time.getUTCHours()).slice(-2) + ':' + ('0' + time.getUTCMinutes()).slice(-2));
	}, [timestamp])


	return (
		<div className="_AppInfo">
			<div className="_AppInfo_DNT">
				DNT
			</div>
			<div className="_AppInfo_status">
				<div className="_AppInfo_status_title">
					Platform status
				</div>
				<div className="_AppInfo_status_value">
					<div className="_AppInfo_status_value_circle"/>
					Operational
				</div>
			</div>
			<div className="_AppInfo_time">
				<div className="_AppInfo_time_title">
					Current time
				</div>
				<div className="_AppInfo_time_value">
					{time} UTC
				</div>
			</div>
		</div>
	)
}

export default AppInfo;