import { FC, useEffect } from "react";
import { Connector, useAccount } from "wagmi";
import { Session } from "@chromia/ft4";

import { useStoreDispatch, useStoreSelector } from "../../../store/store";


const WagmiComp: FC = () => {

	
	const { address } = useAccount();
	const { session } = useStoreSelector(store => store.chromia);
	// const { clearAppState, clearChrState, clearModalsState, clearUIstate } = useStoreDispatch();
	const { connector } = useAccount();
	// const { disconnect } = useDisconnect();
	// const { disconnect_app } = useStoreDispatch();

	useEffect(() => {
		// console.log('connector: ', connector, '\nsession: ', session);
		asyncConnect(address, connector, session);
		// eslint-disable-next-line
	}, [connector, session, address])

	useEffect(() => {
		// console.log('connector: ', connector);
		const changeAccount = () => {
			// console.log('account changed');
			window.location.reload()
		}
		const disconnectAccount = () => {
			// console.log('account disconected');
			// disconnect_app()
		}
		if (connector) {
			connector.on('change', changeAccount);
			connector.on('disconnect', disconnectAccount);
		}
		return () => {
			connector?.off("change", changeAccount);
			connector?.off('disconnect', disconnectAccount);
		}
		// eslint-disable-next-line
	}, [connector])

	const asyncConnect = async (
		client_addr: string | undefined,
		connector: Connector<any, any> | undefined,
		session: Session | null
	) => {
		if (client_addr && connector && !session){
			let chr_connect = await import('../../..//utils/chromia/connect');
			chr_connect.connect(connector, client_addr);
		}
	}

	return null;
}

export default WagmiComp;