import { iBet, iConfig, iAppContract, iOrder, iPrice, iTokenContract, tAvailableCurrencies, iClosedBet, iLastInactiveBet, iLastActiveBet } from "../../interfaces/interfaces"
import { tDispatch } from "../store"
import { eAppActionTypes } from "./appTypes"

export const clearAppState = () => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.CLEAR_STATE,
		})
	}
}

export const setApi = (currency: tAvailableCurrencies, api: string) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_API,
			payload: {
				currency,
				api
			}
		})
	}
}

export const setConfig = (currency: tAvailableCurrencies, config: iConfig) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CONFIG,
			payload: {
				currency,
				config
			}
		})
	}
}

export const setIsInited = (value: boolean) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_IS_INITED,
			payload: value
		})
	}
}

export const setCurrentCurrency = (currency: tAvailableCurrencies) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CURRENT_CURRENCY,
			payload: currency
		})
	}
}

export const setTimestamp = (ts: number) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_TIMESTAMP,
			payload: ts
		})
	}
}

export const setAppContract = (currency: tAvailableCurrencies, contract: iAppContract) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_APP_CONTRACT,
			payload: {
				contract,
				currency
			}
		})
	}
}

export const setTokenContract = (currency: tAvailableCurrencies, contract: iTokenContract) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_TOKEN_CONTRACT,
			payload: {
				contract,
				currency
			}
		}) 
	}
}

export const setBestOrder = (currency: tAvailableCurrencies, order: iOrder) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_BEST_ORDER,
			payload: {
				order,
				currency
			}
		})
	}
}

export const setCurrentBet = (currency: tAvailableCurrencies, bet: iBet) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CURRENT_BET,
			payload: {
				bet,
				currency
			}
		})
	}
}

export const setHistoryBets = (currency: tAvailableCurrencies, bets: iClosedBet[]) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_HISTORY_BETS,
			payload: {
				bets,
				currency
			}
		})
	}
}

export const setLastBets = (currency: tAvailableCurrencies, bets: {activeBets: iLastActiveBet[], inactiveBets: iLastInactiveBet[]} ) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_LAST_BETS,
			payload: {
				bets,
				currency
			}
		})
	}
}

export const setLastOrder = (currency: tAvailableCurrencies, bet: iClosedBet) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_LAST_ORDER,
			payload: {
				bet,
				currency
			}
		})
	}
}

// temp
export const setCurrentPrice = (price: iPrice) => {
	return (dispatch : tDispatch) => {
		dispatch({
			type: eAppActionTypes.SET_CURRENT_PRICE,
			payload: price
		})
	}
}