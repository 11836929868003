import { FC, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useStoreSelector } from "../../store/store";

import Header from "../Header/Header";
import ModalsController from "../modals/ModalsController/ModalsController";
import StartUpPage from "../Pages/StartUpPage/StartUpPage";
import Spinner from "../UI/Spinner/Spinner";
import BalanceUpdater from "../utilComponents/BalanceUpdater/BalanceUpdater";
import ChainHandler from "../utilComponents/ChainHandler/ChainHandler";
import EscapeHandler from "../utilComponents/EscapeHandler/EscapeHandler";
import LastOrderController from "../utilComponents/LastOrderController/LastOrderController";
import Web3Comp from "../utilComponents/Web3Comp/Web3Comp";
import WindowWidthController from "../utilComponents/WindowWidthController/WidthController";
import WagmiComp from "../utilComponents/WagmiComp/WagmiComp";

const MainPage = lazy(() => import('../Pages/MainPage/MainPage'));
const SettingsPage = lazy(() => import('../Pages/SettingsPage/SettingsPage'));
const HedgerPage = lazy(() => import('../Pages/HedgerPage/HedgerPage'));


const App: FC = () => {

	const { isInited } = useStoreSelector(store => store.app);

	return (
		<Suspense
			fallback={<Spinner/>}
		>
			{isInited?
				<>
					<Header/>
					<Switch>
						<Route exact path='/settings' component={SettingsPage}/>
						<Route exact path='/hedger' component={HedgerPage}/>
						<Route exact path='/' component={MainPage}/>
						<Redirect to='/'/>
					</Switch>
					<BalanceUpdater/>
					<Web3Comp/>
					<WagmiComp/>
					<ModalsController/>
					<EscapeHandler/>
					<LastOrderController/>
					<WindowWidthController/>
					<ChainHandler/>
				</>
			:
				<StartUpPage/>
			}
		</Suspense>
	)
}

export default App;